import { Injectable } from '@angular/core';
import { UserService } from '../api/services/user.service';
import { MapService } from '../api/services/map.service';
import { AccessPermissions, Permission } from './map-user-roles.enum';
import { get, isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapRoleService {
  constructor(private mapService: MapService, private userService: UserService) {
  }

  hasCurrentUserPermission(permission: Permission): boolean {
    const map = this.mapService.getCurrentSelectedMapFromStore();
    const user = this.userService.getCurrentUserFromStorage();
    if (isEmpty(map.userRoles)) {
      return false;
    }

    const userRoles = map.userRoles.filter(role => role.user.id === user.id);
    const accessPermissions = AccessPermissions;
    const role = userRoles.find(role => role.user.id === user.id);
    if (role) {
      // @ts-ignore
      return get(accessPermissions, role.role.toLowerCase()).includes(permission);
    } else {
      return false;
    }
  }
}
