import { Directive, effect, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MapRoleService } from '../acl/map-role.service';
import { MapService } from '../api/services/map.service';
import { Permission } from '../acl/map-user-roles.enum';

@Directive({
  selector: '[hasMapPermission]',
  standalone: true
})
export class HasMapPermissionDirective {
  private permission: Permission = 'view';

  constructor(
    private mapRoleService: MapRoleService,
    private mapService: MapService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    effect(() => {
      const changedMap = this.mapService.selectedMap();
      if (changedMap) {
        this.updateView();
      }
    });
  }

  @Input('hasMapPermission') set permissionInput(permission: Permission) {
    this.permission = permission;
    this.updateView();
  }

  private updateView() {
    const currentMap = this.mapService.getCurrentSelectedMapFromStore();
    this.viewContainer.clear();
    if (!currentMap || !currentMap.id) {
      return;
    }

    const hasPermission = this.mapRoleService.hasCurrentUserPermission(this.permission);
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
