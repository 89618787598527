import { Component, effect, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { OrderByPipe } from '../../pipes/orderBy.pipe';
import { MetaData } from '../../../api/interfaces/metadata';
import { MarkdownService } from '../../helpers/markdown.service';
import { marked } from 'marked';
import { ExternalLinksDirective } from '../../directives/external-links.directive';


@Component({
  standalone: true,
  selector: 'maporium-meta-viewer',
  template: `
    <ng-container *ngIf="metadata">
      @for (meta of metadata | orderBy: 'order'; let last = $last; track $index) {
        <div class="row no-gutter align-items-start px-0 no-gutter mb-1 example-box">
          <div class="col-4 px-0 justify-content-end d-flex">
            <span class="key-input break-line">{{ meta.key }}</span>
          </div>

          <!-- Value Input -->
          <div class="col-8 px-0 ps-1 justify-content-start d-flex">
            <div [ngSwitch]="meta.type">
              <span class="break-line"
                    *ngSwitchCase="'date'">{{ meta.value | date: 'MMMM d, yyyy \\'at\\' HH:mm' }}</span>
              <ng-container *ngSwitchDefault>
                <span class="break-line pre-line markdown" appExternalLinks [innerHTML]="getMetValue(meta)"></span>
              </ng-container>
            </div>
          </div>
          <mat-divider class="mt-1" *ngIf="!last"></mat-divider>
        </div>
      }
    </ng-container>

  `,
  imports: [
    MatDividerModule,
    CommonModule,
    OrderByPipe,
    ExternalLinksDirective
  ],
  styleUrls: ['./meta-data-viewer.component.scss']
})
export class MetaDataViewerComponent {
  @Input() metadata: MetaData[] | undefined;

  public isMarkdownEnabled = false;

  constructor(private markdownService: MarkdownService) {
    effect(() => {
      this.isMarkdownEnabled = this.markdownService.markdownEnabledSignal;
    });
  }

  getMetValue(meta: MetaData) {
    return MarkdownService.cleanUpMarkdown(marked(MarkdownService.convertToMarkdown(meta.value)) as string);
  }
}
