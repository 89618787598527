import { effect, Injectable } from '@angular/core';
import * as cytoscape from 'cytoscape';
import { Core, EdgeSingular, NodeSingular } from 'cytoscape';
import { NodeService } from '../../api/services/node.service';
import { BehaviorSubject, concatMap, from, fromEvent, mergeMap, of, Subject } from 'rxjs';
import { SidebarService } from '../../sidebar-form/sidebar.service';
import { MapStateService } from '../../api/services/map-state.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { SettingsService } from '../../api/services/setting.service';
import { CyMaporiumServiceCore } from './cy-maporium-service.core';
import {
  centeringInProgress,
  ExpandCollapseInProgress,
  invalidateGeneralPanZoom,
  invalidatePanZoomForCollapsedNode,
  restoreSavedPanZoomForCollapsedNode,
  setPanZoomForCollapsedNode
} from '../../../cytoscape-extensions/pan-zoom-storage';
import { first, set } from 'lodash';
import { GraphNode, GraphNodeLink } from '../../api/interfaces/graph-node.interface';
import { isRestoringPanZoom } from '../../shared/helpers/signal.helpers';

@Injectable({
  providedIn: 'root'
})
export class CytoscapeService extends CyMaporiumServiceCore {
  public static menuChangedSubject = new Subject<void>();
  public linkingCompleteSubject = new BehaviorSubject(false);
  public readonly ZOOM_PERCENTAGE_CONTAINER = 0.9;
  public readonly ZOOM_PERCENTAGE_NODE = 0.45;

  private boxSelectionElements: any[] = [];
  private dragStarted = false;

  constructor(private nodeService: NodeService,
              private mapStateService: MapStateService,
              private sidebarService: SidebarService) {
    super();
    effect(() => {
      if (!this.cy) return;
      const isEditMode = this.sidebarService.getEditModeSignal();
      if (isEditMode) {
        this.cy.elements().addClass('edit-mode');
      } else {
        this.cy.elements().removeClass('edit-mode');
      }
    });
  }

  public resetAllSelected() {
    if (!this.cy) return;
    DashboardComponent.tappedNode = null;
    DashboardComponent.tappedLink = null;
    DashboardComponent.multiSelectedElements = [];
    if (!this.cy?.elements()) {
      return;
    }
    this.cy.elements().forEach((n) => {
      n.removeClass('highlighted');
      n.removeData('selected');
      n.removeClass('multi');
      n.unselect();
    });
  }

  public centerCanvas(zoom = true,
                      preventPanZoomStoring = false,
                      forceCenter = false,
                      animate = true) {
    if (!this.cy || isRestoringPanZoom() && !forceCenter) return;

    centeringInProgress.set(preventPanZoomStoring);
    invalidatePanZoomForCollapsedNode();
    invalidateGeneralPanZoom();
    this.centerOnElements(this.cy, this.cy.elements(), zoom, 1, 16, animate);
  }

  public centerOnElements(cy: Core,
                          elements: any,
                          zoom = false,
                          percentage = 1,
                          margin = 16,
                          animate = true) {
    const sidebarElement = document.getElementsByClassName('drawer')[0] as HTMLElement;
    const toolbarElement = document.getElementsByClassName('navbar')[0] as HTMLElement;

    const sidebarWidth = sidebarElement?.offsetWidth || 0;
    const toolbarHeight = toolbarElement?.offsetHeight || 0;
    const isSidebarToggled = sidebarWidth > 0;

    const viewportSize = {
      width: isSidebarToggled ? cy.width() - sidebarWidth : cy.width(),
      height: cy.height() - toolbarHeight
    };

    const panZoomTransition = (pan: any,
                               zoom: any | null,
                               shouldAnimate = true) => {
      let params = {
        pan
      };
      if (zoom) {
        set(params, 'zoom', zoom);
      }
      if (shouldAnimate) {
        cy.animate({
          ...params,
          duration: 500,
          easing: 'ease',
          complete() {
            ExpandCollapseInProgress.set(false);
            centeringInProgress.set(false);
          }
        });
      } else {
        cy?.pan(params.pan);
        //@ts-ignore
        if (params.zoom) {
          //@ts-ignore
          cy?.zoom(params.zoom);
        }
        ExpandCollapseInProgress.set(false);
        centeringInProgress.set(false);
      }
    };

    // Filter out elements where data('deleted') is true or data('excludedFromState') is true
    const filteredElements = elements.filter((ele: any) => !ele.hidden());

    // Calculate the bounding box for the collection of filtered elements and add the margin
    const boundingBox = filteredElements.boundingBox();
    const boundingBoxWithMargin = {
      x1: boundingBox.x1 - margin,
      y1: boundingBox.y1 - margin,
      x2: boundingBox.x2 + margin,
      y2: boundingBox.y2 + margin
    };

    // Find the center of the bounding box with margin
    const targetPosition = {
      x: (boundingBoxWithMargin.x1 + boundingBoxWithMargin.x2) / 2,
      y: (boundingBoxWithMargin.y1 + boundingBoxWithMargin.y2) / 2
    };

    const currentPan = cy.pan();
    const currentZoom = cy.zoom();

    // Convert the target position to account for the current zoom level
    const zoomedTargetPosition = {
      x: targetPosition.x * currentZoom,
      y: targetPosition.y * currentZoom
    };

    // Calculate the new pan position to center the elements
    const newPan = {
      x: currentPan.x - (zoomedTargetPosition.x + currentPan.x - viewportSize.width / 2),
      y: currentPan.y - (zoomedTargetPosition.y + currentPan.y - viewportSize.height / 2)
    };

    if (zoom) {
      // Calculate the zoom level to fit the bounding box within the viewport
      const fitZoomLevel = Math.min(
        viewportSize.width / (boundingBoxWithMargin.x2 - boundingBoxWithMargin.x1),
        viewportSize.height / (boundingBoxWithMargin.y2 - boundingBoxWithMargin.y1)
      );

      // Adjust the zoom level based on the percentage
      const zoomLevel = fitZoomLevel * percentage;

      // Calculate the new pan position with the new zoom level
      const newZoomedTargetPosition = {
        x: targetPosition.x * zoomLevel,
        y: targetPosition.y * zoomLevel
      };

      const newZoomedPan = {
        x: (viewportSize.width / 2) - newZoomedTargetPosition.x,
        y: (viewportSize.height / 2) - newZoomedTargetPosition.y + toolbarHeight / 2
      };

      ExpandCollapseInProgress.set(true);
      panZoomTransition(newZoomedPan, zoomLevel, animate);
    } else {
      ExpandCollapseInProgress.set(true);
      // Animate the graph to the new pan position
      panZoomTransition(newPan, null, animate);
    }
  }

  //@ts-ignore
  getNodePagePosition(node: NodeSingular, options: { xOffset: number, yOffset: number, centerOnNode: boolean } = {
    xOffset: 0,
    yOffset: 0,
    centerOnNode: true
  }) {
    if (!this.cy) return null;
    const cy = this.cy;
    const pos = node.position();
    const pan = cy.pan();
    const zoom = cy.zoom();

    // Convert model coords to rendered coords in the Cytoscape container.
    const renderedX = pos.x * zoom + pan.x;
    const renderedY = pos.y * zoom + pan.y;

    // @ts-ignore
    const rect = cy.container().getBoundingClientRect();
    const pageXOffset = window.pageXOffset || document.documentElement.scrollLeft;
    const pageYOffset = window.pageYOffset || document.documentElement.scrollTop;

    let left = rect.left + pageXOffset + renderedX;
    let top = rect.top + pageYOffset + renderedY;

    if (options.centerOnNode) {
      const nodeWidth = node.renderedWidth();
      const nodeHeight = node.renderedHeight();
      left -= nodeWidth / 2;
      top -= nodeHeight / 2;
    }

    left += options.xOffset;
    top += options.yOffset;

    return { left: left - 15, top };
  }


  /**
   * Finds the center-most node relative to the visible canvas.
   * @returns {cytoscape.NodeSingular | null} The center-most node or null if no visible nodes.
   */
  getCenterMostNode(sidebarWidth: number): cytoscape.NodeSingular | null {
    if (!this.cy) {
      console.error('Cytoscape instance is not initialized.');
      return null;
    }

    const pan = this.cy.pan();
    const zoom = this.cy.zoom();

    // Calculate the horizontal center of the visible portion of the canvas.
    const pixelCenterX = (this.cy.width() - sidebarWidth) / 2;
    const pixelCenterY = this.cy.height() / 2;

    // Convert to model coordinates
    const viewportCenter = {
      x: (pixelCenterX - pan.x) / zoom,
      y: (pixelCenterY - pan.y) / zoom
    };

    const visibleNodes = this.cy.nodes(':visible');
    if (visibleNodes.length === 0) {
      console.warn('No visible nodes found.');
      return null;
    }

    // A node is compound if it has children
    const isCompoundNode = (node: cytoscape.NodeSingular) => (node.data()?.children?.length ?? 0) > 0;

    // Separate compound and leaf nodes
    const compoundNodes = visibleNodes.filter(isCompoundNode);

    // Leaf nodes that are not children of compound nodes
    // If a leaf node has a parent that is compound, exclude it.
    const leafNodes = visibleNodes.filter((node) => {
      const isLeaf = (node.data()?.children?.length ?? 0) === 0;
      if (!isLeaf) return false;

      const parent = node.parent();
      //@ts-ignore
      if (parent && isCompoundNode(parent)) {
        // This leaf node belongs to a compound node, so exclude it
        return false;
      }

      return true;
    });

    // Helper to find the closest node and its distance
    const findClosestNodeWithDistance = (nodes: cytoscape.CollectionReturnValue): {
      node: cytoscape.NodeSingular | null;
      distance: number
    } => {
      let closestNode: cytoscape.NodeSingular | null = null;
      let minDistance = Infinity;

      nodes.forEach((node) => {
        const position = node.position();
        const dx = position.x - viewportCenter.x;
        const dy = position.y - viewportCenter.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < minDistance) {
          minDistance = distance;
          closestNode = node;
        }
      });

      return { node: closestNode, distance: minDistance };
    };

    const compoundResult = compoundNodes.length > 0 ? findClosestNodeWithDistance(compoundNodes) : {
      node: null,
      distance: Infinity
    };
    const leafResult = leafNodes.length > 0 ? findClosestNodeWithDistance(leafNodes) : {
      node: null,
      distance: Infinity
    };

    // Compare distances and return whichever is closer
    // If a compound node is closer, it automatically takes precedence.
    // Leaf nodes that belong to a compound node are no longer considered.
    if (compoundResult.distance < leafResult.distance) {
      return compoundResult.node;
    } else {
      if (leafResult.node) {
      }
      return leafResult.node;
    }
  }






  public expandMultipleNodes(nodes: cytoscape.NodeSingular[],
                             update = false,
                             savePan = true) {
    //@ts-ignore
    const api = this.cy.expandCollapse('get');

    const handler = (node: NodeSingular) => {

      api.expand(node);
      node.addClass('expanded');
      node.removeClass('collapsed');
      node.data('collapsed', false);
      // @ts-ignore
      const child = node.children();
      // @ts-ignore
      child.forEach((c: any) => {

        if (c.data('collapsed')) {
          c.data('collapsed', true);
          c.addClass('collapsed');
          c.removeClass('expanded');
          api.collapse(c);
        }
      });
    };
    nodes.forEach((node: any) => {
      handler(node);
    });
    if (this.cy) {
      const node = first(nodes);

      if (node) {
        if (savePan) {
          setPanZoomForCollapsedNode(node.data('id'), true);
          this.centerOnElements(this.cy, first(nodes), true, this.ZOOM_PERCENTAGE_CONTAINER);
        }
      }
    }
    if (update) {
      return from(nodes).pipe(
        mergeMap((node: any) => {
          handler(node);
          return of(node.data());
        })
      );
    } else {
      return of(null);
    }

  }

  public expandNode(event: any, update = false) {
    //@ts-ignore
    const api = this.cy.expandCollapse('get');
    const node = event.target.data();
    const handler = () => {
      api.expand(event.target);
      event.target.addClass('expanded');
      event.target.removeClass('collapsed');
      // @ts-ignore
      const child = event.target.children();
      // @ts-ignore
      child.forEach((c: any) => {
        if (c.data('collapsed')) {
          c.data('collapsed', true);
          c.addClass('collapsed');
          c.removeClass('expanded');
          api.collapse(c);
        }
      });
    };

    node.collapsed = false;
    handler();
    if (this.cy) {
      setPanZoomForCollapsedNode(event.target.data('id'), true);
      this.centerOnElements(this.cy, event.target, true, this.ZOOM_PERCENTAGE_CONTAINER);
    }
    if (update) {
      return of(node);
    } else {
      return of(null);
    }

  }

  collapseMultipleNodes(nodes: cytoscape.NodeSingular[],
                        update = false, restore = true) {
    //@ts-ignore
    const api = this.cy.expandCollapse('get');
    const handler = (node: NodeSingular) => {
      if (!node.isParent()) {
        return;
      }
      api.collapse(node);
      node.addClass('collapsed');
      node.removeClass('expanded');
      node.data('collapsed', true);
    };
    nodes.forEach((node: any) => {
      handler(node);
    });
    if (restore) {
      this.restorePanZoomForCollapsedNode(first(nodes)?.data('id'));
    }
    if (update) {
      return from(nodes).pipe(
        mergeMap((node: any) => {
          handler(node);
          return of(node.data());
        })
      );
    } else {
      return of(null);
    }
  }

  public collapseNode(event: any, update = false) {
    //@ts-ignore
    const api = this.cy.expandCollapse('get');
    const node = event.target.data();
    node.collapsed = true;
    const handler = () => {
      api.collapse(event.target);
      event.target.addClass('collapsed');
      event.target.removeClass('expanded');
    };
    handler();
    if (update) {
      return of(node);
    } else {
      return of(null);
    }
  }

  public restorePanZoomForCollapsedNode(nodeId: string) {
    if (!nodeId) return;
    if (this.cy) {
      restoreSavedPanZoomForCollapsedNode(nodeId, this.cy);
    }
  }

  public initAnchors(edges: EdgeSingular[],
                     type: string) {
    if (!this.cy) return;
    const cy = this.cy;
    // @ts-ignore
    if (!cy.edgeEditing) return;
    // @ts-ignore
    const edgeEditingInstance = cy.edgeEditing('get');

    let edgeEditingType = type === 'BEZIER' ? 'none' : type === 'STRAIGHT' ? 'bend' : 'control';
    edgeEditingInstance.initAnchorPoints(edges, edgeEditingType);
  }

  protected addEventListeners(): void {
    if (!this.cy) return;

    // this.cy.on('drag', 'node', (event) => this.onNodeDrag(event));
    this.cy.on('free', 'node', this.onNodeDragFree.bind(this));
    this.cy.on('grab', 'node', this.onGrabNode.bind(this));
    // TODO: No zoom changes MAP-614 - Display all nodes at all times
    // this.cy.on('zoom', () => this.debouncedZoomLevelChange());

    this.cy.on('drag', (event) => {
      if (!this.cy) return;

      const draggedDistance = Math.abs(event.target.position().x - event.target.data('grabbedPosition').x) + Math.abs(event.target.position().y - event.target.data('grabbedPosition').y);
      this.draggedDistance = draggedDistance;
      if (draggedDistance > 1) {
        this.dragStarted = true;

        event.target.style = {
          'overlay-opacity': SettingsService.sharedValues.node.nodeDragOpacity,
          'overlay-padding': '2px',
          'opacity': () => 0
        };
        const isMultiSelection = this.cy.$(':selected').length > 1;

        if (!isMultiSelection) {
          //Since we are not in multiselection we need to check if dragged element(with state) is selected or not
          // if it is not selected we need to unselect all and select the dragged element
          if (this.cy.$(':selected').id() !== event.target.id()) {
            if (event.target) {
              this.cy.$(':selected').filter((node) => node !== event.target).unselect();
            }

            if (event.target.data('draggedByParent')) {
              return;
            }

            event.target.addClass('grabbed');
            const currentState = this.mapStateService.getCurrentLocalState();
            let elementData = event.target.data();
            if (currentState?.id !== undefined) {
              const statedElement = elementData.states?.find((s: any) => s.stateId === currentState.id);
              if (statedElement?.link || statedElement?.node) {
                if (event.target.isNode()) {
                  elementData = statedElement?.node;
                } else {
                  elementData = statedElement?.link;
                }
              }
            }

            if (event.target.isNode()) {
              DashboardComponent.tappedNode = elementData;
              DashboardComponent.tappedLink = null;
            } else {
              DashboardComponent.tappedLink = elementData;
              DashboardComponent.tappedNode = null;
            }

            event.target.select();
          }
        } else {
          // if the dragged node is not in the selection then unselect all and select the dragged node
          if (!event.target.selected()) {
            this.cy.$(':selected').forEach((node) => {
              node.removeClass('grabbed');
              node.addClass('force-unselect');
              node.unselect();
            });
            event.target.select();
            event.target.addClass('grabbed');
            if (event.target.isNode()) {
              DashboardComponent.tappedNode = event.target.data();
              DashboardComponent.tappedLink = null;
            } else {
              DashboardComponent.tappedLink = event.target.data();
              DashboardComponent.tappedNode = null;
            }
          }
        }
      }

      if (!this.mapStateService.isDefaultState()) {
        return;
      }

      const draggedNode = event.target;
      if (draggedNode.data('draggedByParent')) return; // Exclude nodes dragged by parents

      const mousePosition = this.mousePosition;
      let potentialDropTargets;
      if (draggedNode.isChild()) {
        // If the dragged node is a child, only consider its parent and siblings
        potentialDropTargets = draggedNode.parent().descendants().filter((decendant: NodeSingular) => {
          return decendant !== draggedNode && decendant.parent().data('id') !== draggedNode.data('id');
        }).difference(draggedNode);

      } else {
        potentialDropTargets = this.cy.nodes().difference(draggedNode).reduce((acc, node: NodeSingular) => {
          // Exclude the dragged node itself and its descendants
          if (node === draggedNode || draggedNode.descendants().some((descendant: cytoscape.NodeSingular) => descendant === node)) {
            return acc;
          }

          // If node is a compound node, add its children that are not the dragged node or its descendants
          if (node.isParent()) {
            const children = node.children().difference(draggedNode.descendants());
            acc = acc.union(children);
          }


          // Add the node itself if it hasn't been excluded by the conditions above
          acc = acc.union(node);

          // Remove all ancestors of the dragged node
          acc = acc.difference(draggedNode.ancestors());


          return acc;
        }, this.cy.collection());
      }
      // Exclude nodes from potentialDropTargets that are selected
      potentialDropTargets = potentialDropTargets.difference(this.cy.$(':selected'));
      // Determine the closest node to the mouse position
      const closestNode = this.findClosestNode(mousePosition, potentialDropTargets, 5);

      // Clear any previous highlights
      this.cy.nodes('.highlighted').removeClass('highlighted');

      if (closestNode) {
        // Highlight the closest node if it's within the threshold
        // @ts-ignore
        closestNode.addClass('highlighted');
      }

    });

    this.cy.on('free', (event) => {
      // @ts-ignore
      this.cy.nodes('.highlighted').removeClass('highlighted');
    });

    // Invalidate stored pan-zoom state when the user pans or zooms the graph
    this.cy.on('pan zoom', (event) => {
      const expandCollapseInProgress = ExpandCollapseInProgress();
      if (expandCollapseInProgress) return;
      // only invalidate if moved beyond the threshold
      invalidatePanZoomForCollapsedNode();
    });
    this.handleBoxSelectionEvents(this.cy);
    this.handleForcePan();
  }

  private handleBoxSelectionEvents(cy: Core) {
    cy.on('box', (event) => {
      this.boxSelectionElements.push(event.target as any);
    });
    cy.on('boxend', async () => {
      await new Promise(resolve => setTimeout(resolve, 100));
      if (this.boxSelectionElements.length === 0) {
        return;
      }

      DashboardComponent.multiSelectedElements = this.boxSelectionElements.map((e: any) => e.data());

      const firstElement = first(this.boxSelectionElements);

      if (firstElement.isNode()) {
        DashboardComponent.tappedNode = firstElement.data() as GraphNode;
      } else {
        DashboardComponent.tappedLink = firstElement.data() as GraphNodeLink;
      }

      firstElement.select();
      this.boxSelectionElements = [];
    });
  }

  private handleForcePan() {
    if (!this.cy) return;
    fromEvent<MouseEvent>(document, 'mousemove').subscribe((event) => {
      if (!this.cy) return;
      if (event.ctrlKey) {
        const dx = event.movementX;
        const dy = event.movementY;

        // Adjust the pan sensitivity as needed
        const panSensitivity = 1;
        // @ts-ignore
        document.documentElement.style.cursor = 'grab';
        // Pan the Cytoscape viewport
        this.cy.panBy({ x: dx * panSensitivity, y: dy * panSensitivity });

      } else {
        // @ts-ignore
        document.documentElement.style.cursor = 'auto';
      }
    });
  }

  private onNodeDragFree(event: cytoscape.EventObject): void {
    this.draggedDistance = 0;
    if (!this.cy) return;
    if (!this.mapStateService.isDefaultState()) {
      this.cy.elements().removeClass('grabbed');
      return;
    }

    if (!this.dragStarted) {
      const draggedNode = event.target;
      draggedNode.removeClass('grabbed');
      draggedNode.parents().removeClass('grabbed');
      return;
    }

    const draggedNode = event.target;
    draggedNode.removeClass('grabbed');
    draggedNode.parents().removeClass('grabbed');

    if (this.linkingCompleteSubject.getValue()) {
      this.linkingCompleteSubject.next(false);
    }
    if (draggedNode.data('draggedByParent')) return; // Skip if dragged by parent

    // Find the highlighted node (potential drop target)
    const highlightedNode = this.cy.nodes('.highlighted').first();
    const allSelectedNodes = this.cy.nodes(':selected');

    if (highlightedNode.nonempty()) {
      // Handle the drop logic with highlightedNode as the target
      const updatedNodesData = [];
      if (allSelectedNodes.length > 1) {
        // Handle multiple nodes being dragged
        allSelectedNodes.forEach((selectedNode) => {
          selectedNode.move({ parent: highlightedNode.id() });
          updatedNodesData.push({
            childIds: selectedNode.parent().children().map((child: any) => child.id()),
            parentId: highlightedNode.id()
          });
        });
      } else {
        draggedNode.move({ parent: highlightedNode.id() });
        updatedNodesData.push({
          childIds: draggedNode.parent().children().map((child: any) => child.id()),
          parentId: draggedNode.parent().id()
        });
      }


      from(updatedNodesData)
        .pipe(
          concatMap((data) => this.nodeService.updateParent(data))
        )
        .subscribe();

      if (highlightedNode.hasClass('collapsed')) {
        //@ts-ignore
        const api = this.cy.expandCollapse('get');
        highlightedNode.data('collapsed', false);
        api.expand(highlightedNode);
        highlightedNode.addClass('expanded').removeClass('collapsed');
      }
    }

    // Remove highlighting and any additional cleanup
    highlightedNode.removeClass('highlighted');
    if (allSelectedNodes.length > 0) {
      allSelectedNodes.forEach((n) => this.unmarkChildIfDraggedByParent(n));
    }
    this.unmarkChildIfDraggedByParent(draggedNode);

    this.dragStarted = false;

    //If we release a dragged container , we should select it
    if (draggedNode.isParent()) {
      draggedNode.select();
      DashboardComponent.tappedNode = draggedNode.data();
    }
  }

  private onGrabNode(event: cytoscape.EventObject): void {
    if (!this.cy) return;
    const node = event.target;
    this.addGrabbedPositionToAllDescendants(node);
    this.markChildIfDraggedByParent(node);
  }

}
