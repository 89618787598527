export enum MapUserRoles {
  VIEWER = 'viewer',
  COMMENTER = 'commenter',
  EDITOR = 'editor',
  MANAGER = 'manager',
}

export const AccessPermissions = {
  [MapUserRoles.VIEWER]: ['view'],
  [MapUserRoles.COMMENTER]: ['view', 'comment'],
  [MapUserRoles.EDITOR]: ['view', 'comment', 'edit'],
  [MapUserRoles.MANAGER]: ['view', 'comment', 'edit', 'manage']
};

export declare type Permission = 'view' | 'comment' | 'edit' | 'manage';
