import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { User } from '../api/interfaces/user.interface';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { MapService } from '../api/services/map.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MapUserRoles } from '../acl/map-user-roles.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { HasMapPermissionDirective } from '../packages/has-map-permission.directive';

@Component({
  selector: 'maporium-share-map-dialog',
  styles: [`
    ::ng-deep {

    .share-map-form {
      --mdc-theme-primary: #000000;
      .mdc-floating-label {
        color:  #000000 !important;
      }
    }

    }
  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0">
        <span class="material-symbols-rounded me-2">person_add</span>
        {{ 'SHARE_DIALOG.TITLE' | translate }}
      </h2>
      <mat-icon class="material-symbols-rounded close-button" mat-dialog-close
                matTooltip="{{ 'SHARE_DIALOG.CLOSE_TOOLTIP' | translate }}">
        close
      </mat-icon>
    </div>

    <div mat-dialog-content class="p-3 px-4">
      <!-- Search Bar -->
      <form class="share-map-form">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{ 'SHARE_DIALOG.SEARCH_USERS' | translate }}</mat-label>
          <input #userInput
                 matInput
                 [formControl]="userCtrl"
                 [matAutocomplete]="auto"
                 (keydown.enter)="$event.preventDefault()">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
              {{ user }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <mat-error *ngIf="userCtrl.hasError('invalidAutocompleteObject') && userCtrl.dirty">
        {{ 'SHARE_DIALOG.ERROR_INVALID_EMAIL' | translate }}
      </mat-error>

      <!-- Assigned Users List -->
      <div *ngIf="emails.length > 0" class="assigned-users mt-3">
        <h4 class="mb-2">{{ 'SHARE_DIALOG.ASSIGNED_USERS' | translate }}</h4>
        <div *ngFor="let email of emails; let last = last" class="my-2">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              {{ getUserName(email) }} &lt;{{ email }}&gt;
            </div>
            <div class="d-flex align-items-center">
              <!-- Role Button showing icon corresponding to user's role -->
              <button mat-icon-button
                      [matMenuTriggerFor]="roleMenu"
                      (click)="currentEmail = email"
                      matTooltip="{{ 'SHARE_DIALOG.SET_ROLE_TOOLTIP' | translate }}">
                <mat-icon>{{ roleIcon(email) }}</mat-icon>
              </button>
              <!-- Delete Button -->
              <button mat-icon-button color="warn" (click)="remove(email)"
                      matTooltip="{{ 'SHARE_DIALOG.REMOVE_USER_TOOLTIP' | translate }}">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>

        <!-- Role Selection Menu -->
        <mat-menu #roleMenu="matMenu">
          <button mat-menu-item (click)="selectRole(currentEmail, 'VIEWER')">
            <mat-icon>visibility</mat-icon>
            <span>{{ 'SHARE_DIALOG.ROLES.VIEWER' | translate }}</span>
          </button>
          <button mat-menu-item (click)="selectRole(currentEmail, 'COMMENTER')">
            <mat-icon>comment</mat-icon>
            <span>{{ 'SHARE_DIALOG.ROLES.COMMENTER' | translate }}</span>
          </button>
          <button mat-menu-item (click)="selectRole(currentEmail, 'EDITOR')">
            <mat-icon>edit</mat-icon>
            <span>{{ 'SHARE_DIALOG.ROLES.EDITOR' | translate }}</span>
          </button>
          <button mat-menu-item (click)="selectRole(currentEmail, 'MANAGER')">
            <mat-icon>settings</mat-icon>
            <span>{{ 'SHARE_DIALOG.ROLES.MANAGER' | translate }}</span>
          </button>
        </mat-menu>
      </div>

      <!-- Map URL Section -->
      <div class="mt-4">
        <h4 class="mb-2">{{ 'SHARE_DIALOG.MAP_LINK' | translate }}</h4>
        <mat-form-field class="w-100">
          <input [value]="mapUrl" #mapLink matInput disabled>
          <button mat-icon-button
                  matSuffix
                  [cdkCopyToClipboard]="mapUrl"
                  matTooltip="{{ 'SHARE_DIALOG.COPY_MAP_LINK_TOOLTIP' | translate }}"
                  (click)="copyMapLink(mapLink)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <ng-container *hasMapPermission="'manage'">
        <h4>{{ 'EMBED_DIALOG.ACCESS.TITLE' | translate }}</h4>
        <app-select-input
          [labelColSize]="8"
          [dropdownColSize]="4"
          [label]="'EMBED_DIALOG.ACCESS.LEVEL' | translate"
          [formControl]="accessControl"
          [options]="accessOptions">
        </app-select-input>
      </ng-container>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-button (click)="update()" color="accent"
              *ngIf="hasChanges">{{ 'SHARE_DIALOG.SHARE_BUTTON' | translate }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDivider,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    CdkCopyToClipboard,
    TranslatePipe,
    SelectInputComponent,
    HasMapPermissionDirective
  ]
})
export class ShareMapDialogComponent {
  userCtrl = new FormControl('');
  filteredUsers: Observable<string[]>;
  emails: string[] = [];
  allUserEmails: string[] = [];
  allAvailableUsers: User[] = [];
  sharedWithUsers: User[] = [];
  hasChanges = false;
  currentEmail = '';
  userRoles: { [email: string]: MapUserRoles } = {};
  mapUrl = '';
  accessControl = new FormControl('private');
  accessOptions: { key: string, value: string, icon: string }[] = [];

  roleIcons: { [key: string]: string } = {
    VIEWER: 'visibility',
    COMMENTER: 'comment',
    EDITOR: 'edit',
    MANAGER: 'settings'
  };
  //@ts-ignore
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(private userService: UserService,
              private mapService: MapService,
              private dialogRef: MatDialogRef<ShareMapDialogComponent>,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) private data: { mapId: string },
              private translate: TranslateService) {
    this.mapUrl = window.location.href;
    this.userService.getAvailableUsersForMapSharing(data.mapId)
      .subscribe((users: User[]) => {
        this.allUserEmails = users.map(user => user.email) as string[];
        this.allAvailableUsers = users;
      });
    this.accessControl.valueChanges.subscribe(() => this.hasChanges = true);
    this.mapService.findByIdWithRelation(data.mapId).subscribe((map) => {
      this.accessControl.patchValue(map.isPublic ? 'public' : 'private');
      if (map.sharedWith) {
        this.emails = map.sharedWith.map(user => user.email) as string[];
        this.sharedWithUsers = map.sharedWith.map(user => {
          const roleEntry = map.userRoles.find(role => role.user.id === user.id);
          return {
            ...user,
            role: roleEntry ? roleEntry.role.toUpperCase() : 'VIEWER' // Default to 'VIEWER' if no role is found
          };
        });
      }
    });

    this.accessOptions = [
      { key: 'public', value: this.translate.instant('EMBED_DIALOG.ACCESS.PUBLIC'), icon: 'public' },
      { key: 'private', value: this.translate.instant('EMBED_DIALOG.ACCESS.PRIVATE'), icon: 'lock' }
    ];
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allUserEmails.slice())),
    );
  }

  roleIcon(email: string): string {
    const user = this.sharedWithUsers.find(user => user.email === email);
    const role = user?.role?.toUpperCase() || 'VIEWER';
    return this.roleIcons[role] || 'visibility';
  }


  add(event: MatChipInputEvent): void {
    if (this.userCtrl.invalid) return;
    const value = (event.value || '').trim();

    if (value) {
      const index = this.allUserEmails.indexOf(value);
      if (index === -1) return;
      this.emails.push(value);
      //remove from allUserEmails
      this.sharedWithUsers.push(this.allAvailableUsers[index]);
      if (index >= 0) {
        this.allUserEmails.splice(index, 1);
      }
    }

    // Clear the input value
    event.chipInput.clear();

    this.userCtrl.setValue(null);
    this.hasChanges = true;
  }

  getUserName(email: string): string {
    if (!email) return '';
    const user = this.sharedWithUsers.find(user => user.email === email);
    if (!user) return '';
    return user?.name as string;
  }

  selectRole(email: string, role: string) {
    this.userRoles[email] = role as MapUserRoles;
    // Look for user in sharedWithUsers and update role
    const user = this.sharedWithUsers.find(user => user.email === email);
    if (user) {
      user.role = role;
    }
    this.hasChanges = true;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.allUserEmails.push(email);
      this.allAvailableUsers.push(this.sharedWithUsers[index]);
      this.emails.splice(index, 1);
      this.sharedWithUsers.splice(index, 1);
    }

    this.hasChanges = true;

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);

    const index = this.allUserEmails.indexOf(event.option.viewValue);
    this.sharedWithUsers.push(this.allAvailableUsers[index]);
    if (index >= 0) {
      this.allUserEmails.splice(index, 1);
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
    this.hasChanges = true;

  }

  update() {
    let usersToShare = this.sharedWithUsers
      .filter(user => !!user.email && this.emails.includes(user.email as string))
      .map(user => ({
        id: user.id,
        role: this.userRoles[user.email as string] || 'viewer'
      }));

    if (usersToShare.length === 0) {
      usersToShare = [];
    }

    if (this.accessControl.value === 'public') {
      this.publish(this.data.mapId).subscribe();
    } else {
      this.unpublish(this.data.mapId).subscribe();
    }
    this.mapService.shareMap(this.data.mapId, { users: usersToShare })
      .subscribe(() => this.dialogRef.close());
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserEmails.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }

  copyMapLink(mapLink: HTMLInputElement) {
    mapLink.select();
    this.snackBar.open(this.translate.instant('SHARE_DIALOG.LINK_COPIED'), '', { duration: 2000 });
  }

  private publish(mapId: string) {
    return this.mapService.openPublicAccessToMap(mapId);
  }

  private unpublish(mapId: string) {
    return this.mapService.closePublicAccessToMap(mapId);
  }
}
